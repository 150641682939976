<template>
  <div class="not-found flex-content center">
    <!-- 网络错误 -->
    <van-empty class="empty-content" image="network" description="页面不存在">
      <van-button round type="primary" class="bottom-button" to="/">返 回 首 页</van-button>
    </van-empty>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive
} from 'vue'
import { Button, Empty } from 'vant'

export default defineComponent({
  name: 'not-found',

  components: {
    [Button.name]: Button,
    [Empty.name]: Empty
  },

  setup (props, context) {
    const state = reactive({})

    return {
      state
    }
  }
})
</script>
<style lang="scss" scoped>
.not-found {
  width: 100%;
  height: 100vh;
  background: #fff;

  .empty-content {
    margin-bottom: 10rem;
  }

  .bottom-button {
    min-width: 16rem;
  }
}
</style>
